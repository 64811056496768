import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const AboutMeLayout = styled.div`
  ${({ theme }) => css`
    margin: 5em 0;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: center;
    align-items: center;
    grid-gap: 5em;

    .profile-pic {
      max-width: 500px;
      min-width: 300px;
      border-radius: 50%;
      height: auto;
      box-shadow: 0px 0px 0px 5px ${theme.colors.black},
        0px 0px 0px 10px ${theme.colors.white};
    }

    .details {
      max-width: 50ch;
      min-width: 30ch;
    }

    @media (max-width: 800px) {
      grid-template-columns: auto;
    }
  `}
`

function IndexPage({
  data: {
    fileName: { childImageSharp },
  },
  location,
}) {
  return (
    <>
      <SEO title="Home" />
      <section>
        <AboutMeLayout>
          <Img
            fluid={childImageSharp.fluid}
            alt="Levi Butcher"
            className="profile-pic"
          />
          <div className="details">
            <h1>Hi, I'm Levi Butcher</h1>
            <p>
              Welcome to my website, If you want to learn more about me check
              out my <Link to="/resume">Resume</Link> page. If you want to see
              the projects I've done, be sure to check out the{' '}
              <Link to="/projects">Projects</Link> page. Also check out my{' '}
              <Link to="/blog">Blog</Link> to see what I've recently been diving
              into
            </p>
          </div>
        </AboutMeLayout>
      </section>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    fileName: file(relativePath: { eq: "levibutcher.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
